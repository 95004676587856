import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';

const MainHeader = defineAsyncComponent(() => import('@/js/global/header/MainHeader.vue'));
const LogoSlider = defineAsyncComponent(() => import('@/js/components/slider/LogoSlider.vue'));
const AppPopup = defineAsyncComponent(() => import('@/js/global/top-menu/AppPopup.vue'));
const Translate = defineAsyncComponent(() => import('@/js/helpers/Translate.vue'));
const CompanionBubble = defineAsyncComponent(() => import('@/js/global/top-menu/CompanionBubble.vue'));
const MarketingCampaignComponents = defineAsyncComponent(() => import('@/js/marketing-campaigns/MarketingCampaignComponents.vue'));
const StoreMenuData = defineAsyncComponent(() => import('@/js/global/menu/partials/StoreMenuData.vue'));
const MainMenu = defineAsyncComponent(() => import('@/js/global/menu/MainMenu.vue'));
const NewsletterInput = defineAsyncComponent(() => import('@/js/newsletter/NewsletterInput.vue'));
const LeaderboardGeneralPlayers = defineAsyncComponent(() => import('@/js/quests/global/components/LeaderboardGeneralPlayers.vue'));

const app = createApp({});
app.use(createPinia());

app.component('Translate', Translate);
app.config.globalProperties.Translate = Translate.methods;

app.component('StoreMenuData', StoreMenuData);
app.component('MainMenu', MainMenu);
app.component('MainHeader', MainHeader);
app.component('LogoSlider', LogoSlider);
app.component('AppPopup', AppPopup);
app.component('CompanionBubble', CompanionBubble);
app.component('MarketingCampaignComponents', MarketingCampaignComponents);
app.component('NewsletterInput', NewsletterInput);
app.component('LeaderboardGeneralPlayers', LeaderboardGeneralPlayers);

app.mount('#app-mount');
